import { graphql, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CommentCard from '../components/CommentCard';
import CommentForm from '../components/CommentForm';
import Layout from '../components/Layout';
import Pagination from '../components/Pagination';
import SEO from '../components/SEO';
import { useContactLinks } from '../hooks/useContactLinks';
import { useLatestPosts } from '../hooks/useLatestPosts';

const ChapterPage = ({ location, data: { contentfulPage: page, allContentfulComment, allContentfulBanner } }) => {
  const intl = useIntl();
  const links = useContactLinks();
  const posts = useLatestPosts();
  const [chapter] = page.chapter;
  const [comments, setComments] = useState(allContentfulComment.edges);

  const handleCommentCreation = (comment) => {
    setComments([
      ...comments,
      { node: { ...comment, displayCreatedAt: intl.formatMessage({ id: 'new-comment-display-created-at' }) } },
    ]);
  };

  const banner = allContentfulBanner.nodes[0];

  return (
    <Layout sticky>
      <SEO
        pathname={location.pathname}
        title={page.title}
        description={page.description?.text}
        og={{ type: 'article', published_time: page.createdAt, section: chapter.title }}
      />

      <article className="relative grid justify-center grid-cols-1 px-4 mt-24 bg-black gap-y-16 shadow-article md:grid-cols-5 md:gap-y-0 md:gap-x-16 md:px-0 md:mt-16">
        <GatsbyImage
          image={page.image.gatsbyImageData}
          quality={100}
          alt=""
          className="w-full border-8 border-white md:col-span-3"
        />

        <div className="md:col-span-2">
          <section className="py-6 mb-16 px-9">
            {banner && banner.image?.gatsbyImageData && (
              <div className="mb-8">
                <p className="text-sm">{banner.label}</p>

                <Link to={banner.url} target="_blank" rel="noreferrer">
                  <GatsbyImage image={banner.image.gatsbyImageData} alt="" className="w-full" />
                </Link>
              </div>
            )}

            <p className="-mb-2 text-sm">{chapter.title}</p>
            <h1 className="mb-0 text-xl font-bold leading-tight">{page.title}</h1>
            <time dateTime={page.createdAt} className="block mb-12 text-sm opacity-80">
              {page.displayCreatedAt}
            </time>

            <p>{page.description?.text}</p>
          </section>

          <section className="py-8 mb-16 bg-white px-9 bg-opacity-10">
            {comments.map(({ node: comment }) => (
              <CommentCard comment={comment} key={comment.id} />
            ))}

            <CommentForm pageContentfulID={page.contentful_id} onCommentCreated={handleCommentCreation} />
          </section>

          <section className="py-6 mb-16 bg-white px-9 bg-opacity-10">
            <nav className="flex flex-row items-center justify-between text-sm flex-nowrap">
              <a href={links.twitter.url} target="_blank" rel="noreferrer">
                Twitter
              </a>

              <a href={links.instagram.url} target="_blank" rel="noreferrer">
                Instagram
              </a>

              <a href={links.patreon.url} target="_blank" rel="noreferrer">
                Patreon
              </a>

              <Link to="/rss">RSS Feed</Link>
            </nav>
          </section>

          <section className="mb-16">
            <h2 className="mb-12">{intl.formatMessage({ id: 'latest-posts-label' })}</h2>

            {posts.map(({ node: post }) => (
              <article className="flex flex-col py-6 mb-6 bg-white flex-nowrap px-9 bg-opacity-10" key={post.slug}>
                <h3 className="mb-2 text-sm font-bold transition-colors duration-300 hover:text-red">
                  <Link to={`/${intl.locale}/blog/${post.slug}`}>{post.title}</Link>
                </h3>
                <p className="text-sm opacity-70">{post.content?.childMarkdownRemark.excerpt}</p>

                <Link to={`/${intl.locale}/blog/${post.slug}`} className="self-end text-sm text-red">
                  {intl.formatMessage({ id: 'post-read-more' })}
                </Link>
              </article>
            ))}
          </section>
        </div>
      </article>

      <Pagination currentPage={page} />
    </Layout>
  );
};

export const query = graphql`
  query ChapterPageQuery($slug: String!, $locale: String!) {
    contentfulPage(slug: { eq: $slug }) {
      id
      contentful_id
      slug
      title
      node_locale
      image {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, quality: 100)
      }
      displayCreatedAt: createdAt(formatString: "MMMM Do, YYYY")
      createdAt
      description {
        text: description
      }
      chapter {
        slug
        title
      }
    }

    allContentfulComment(filter: { page: { slug: { eq: $slug } } }, sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          id
          author
          gravatar
          displayCreatedAt: createdAt(formatString: "MMMM Do, YYYY")
          createdAt
          content {
            text: content
          }
        }
      }
    }

    allContentfulBanner(limit: 1, sort: { fields: updatedAt, order: ASC }, filter: { node_locale: { eq: $locale } }) {
      nodes {
        id
        node_locale
        label
        url
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, quality: 100)
        }
      }
    }
  }
`;

ChapterPage.propTypes = {
  data: PropTypes.shape({
    contentfulPage: PropTypes.shape({
      id: PropTypes.string.isRequired,
      contentful_id: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      node_locale: PropTypes.string.isRequired,
      image: PropTypes.shape({
        gatsbyImageData: PropTypes.object.isRequired,
      }).isRequired,
      displayCreatedAt: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      description: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
      chapter: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
        }),
      ),
    }).isRequired,
  }),
  pageContext: PropTypes.shape({
    pageLocales: PropTypes.shape({
      en: PropTypes.shape({
        contentful_id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        node_locale: PropTypes.string.isRequired,
        chapter: PropTypes.arrayOf(
          PropTypes.shape({
            slug: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }).isRequired,
      es: PropTypes.shape({
        contentful_id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        node_locale: PropTypes.string.isRequired,
        chapter: PropTypes.arrayOf(
          PropTypes.shape({
            slug: PropTypes.string.isRequired,
          }),
        ).isRequired,
      }).isRequired,
    }),
  }).isRequired,
};

export default ChapterPage;
